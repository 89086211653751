.user-profile-img {
  height: 120px;
  width: 120px;
  .profile-picture {
    width: 100%;
    border-radius: 50%;
    border: 4px solid $white;
  }
}
.ul-contact-page__profile {
  display: flex;
  align-items: center;
}
.ul-contact-page__info {
  /* margin-right: 2px; */
  margin-left: 23px;
  width: calc(100% - 126px);
}

// search form
.ul-form-input {
  width: 50%;
  transition: 0.5s ease-in-out;
}

@media only screen and (max-width: 425px) {
  .ul-contact-page__profile {
    text-align: center;
    display: block;
  }
  .ul-contact-page__info {
    margin-left: 0;
    width: 100%;
  }
}

;@import "sass-embedded-legacy-load-done:257";