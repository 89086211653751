.nav-tabs {
    border-bottom: 1px solid #ed682d;
}

.nav-tabs .nav-item .nav-link.active {
    border: 1px solid transparent;
    background: rgb(237 104 45 / 10%);
    border-color: #ed682d #ed682d $background;
}

.dropdown-toggle:after {
    display: inline-block;
    width: 0;
    height: 0;
    right: 5px;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

;@import "sass-embedded-legacy-load-done:216";