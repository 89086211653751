table.tableOne.vgt-table thead tr th {
  background: #fff;
}
table.tableOne.vgt-table {
  font-size: 13px;
}
.vgt-global-search.vgt-clearfix {
  background: #fff;
}
span.chevron.right::after {
  border-left: 6px solid #b0b1b3 !important;
}
span.chevron.left::after {
  border-right: 6px solid #b0b1b3 !important;
}
table.tableOne tbody tr th.line-numbers {
  background: #fff;
}
table.tableOne tbody tr th.vgt-checkbox-col {
  background: #fff;
}
// div.vgt-global-search__input.vgt-pull-left{
//   flex-grow: 1;
// }
input.vgt-input.vgt-pull-left {
  width: auto;
}
div.vgt-wrap__footer.vgt-clearfix {
  background: #fff;
}
.table-alert__box {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
  padding: 0.75rem !important;
}
.gull-border-none {
  border-bottom: none !important;
}
table.tableOne.vgt-table {
  border: none !important;
}
.vgt-wrap__footer.vgt-clearfix {
  border: none !important;
}
.vgt-global-search.vgt-clearfix {
  border: none !important;
}
th.vgt-checkbox-col {
  border-right: none !important;
  border-bottom: 0px solid #dcdfe6;
}
th.line-numbers {
  border-right: none !important;
  border-bottom: 1px solid #dcdfe6;
}
.order-table.vgt-table {
  font-size: 14px;
  border: 0px solid #dcdfe6;
  thead th {
    color: $body-color;

    vertical-align: bottom;
    border-bottom: 0px solid #dcdfe6;
    padding-right: 1.5em;
    background: transparent;
    min-width: 140px !important;
    font-size: 14px;
    font-weight: 600 !important;
  }
  td {
    padding: 10px;
    vertical-align: middle;
    border-bottom: 0px solid #dcdfe6;
    color: $body-color;
    font-size: 14px;
    min-width: 140px !important;
  }
  tbody tr {
    transition: all 0.5s;
    padding: 15px;
    cursor: pointer;
  }
  tbody tr:hover {
    background: #eee;
    border-radius: 10px;
  }
}

;@import "sass-embedded-legacy-load-done:219";