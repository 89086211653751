.login {
    margin: 0 auto;
    justify-content: center;
    display: flex;
    float: none;
    flex-direction: column;
    flex-wrap: nowrap;
}

.login-form {
    width: 50%;
}

.sign-in {
    color: #323ec5;
    font-weight: bold;
}

#login-button {
    background-color: #eb8a61;
    color: white;
    font-size: medium;
    transition-duration: 0.5s;
}

#login-button:hover {
    background: #ed682d;
    color: white;
}
#login-button:active,
#login-button:focus {
    background: #ed682d;
}

.forgot-password-link {
    color: #323ec5;
    font-weight: 600;
}

.register {
    color: #ed682d;
    margin-bottom: 5%;
}

.center-text {
    color: white;
    letter-spacing: 1px;
    font-weight:900;
}

.center-text-bottom {
    color: white;
}

.password-toggle {
    z-index: 9999;
    position: absolute;
    top: 11px;
    right: 4px;
    color: #323ec5;
}

@media only screen and (max-width : 767px) {
    #login-form {
        width: 100%;
    }

    .login {
        width: 96%;
    }

    .back-to-home {
        width: 100%;
        margin-bottom: 10%;
    }

    .logo {
        width: 25%;
    }
  }
