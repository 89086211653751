.tag-custom{
    max-width:1000%!important;
    border-bottom:1px solid #000;

}
input.ti-new-tag-input.ti-valid {

}
.ti-input {
    border: none!important;
}
.ti-tag.ti-valid{
    min-height: 30px;
    background-color:#ed682d!important;
    border-radius: 50rem!important;
    padding: 0 12px!important;

}

;@import "sass-embedded-legacy-load-done:220";