.required {
    display: inline-block;
    position: relative;
    top: -0.2rem;
    right: 0.1rem;
    color: var(--red) !important;
    font-family: "Nunito", sans-serif;
    font-size: 0.85rem;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-weight: 700;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
.toast > .custom-toast-header-error {
    background-color: #f44336!important;
    color: #ffffff!important;
    border-bottom: 0px!important;
}
.custom-toast-body-error {
    background-color: #ffffff;
    color: #000000;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.toast > .custom-toast-header-success {
    background-color: #4caf50!important;
    color: #ffffff!important;
    border-bottom: 0px!important;
}
.custom-toast-body-success {
    background-color: #ffffff;
    color: #000000;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.tab-content {
    padding: 0.5rem 0 0 0 !important;
}

.tab-content > .tab-pane {
    display: none !important;
}

.tab-content > .active {
    display: block !important;
}
