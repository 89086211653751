.reset-password {
    margin: 0 auto;
    justify-content: center;
    display: flex;
    float: none;
    flex-direction: column;
    flex-wrap: nowrap;
}

.reset-password-form {
    width: 50%;
}

.reset-password-title {
    color: #323ec5;
    text-align: center;
}

#reset-password-button {
    background-color: #eb8a61;
    color: white;
    font-size: medium;
    transition-duration: 0.5s;
}

#reset-password-button:hover {
    background: #ed682d;
    color: white;
}

#reset-password-button:active,
#reset-password-button:focus {
    background: #ed682d;
}

.login-link {
    color: #ed682d;
    margin-bottom: 5%;
}

.center-text {
    color: white;
    letter-spacing: 1px;
    font-weight:900;
}

.center-text-bottom {
    color: white;
}

.register-link {
    color: #ed682d;
    margin-bottom: 5%;
    font-weight: 600;
}

.password-toggle-reset {
    z-index: 9999;
    position: absolute;
    top: 10px;
    right: 0px;
    color: #323ec5;
}

@media only screen and (max-width : 767px) {
    .reset-password-form {
        width: 100%;
    }

    .back-to-home {
        width: 100%;
        margin-bottom: 10%;
    }

    .logo {
        width: 28%;
    }
}
