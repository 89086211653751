.popover {
    border: none;
    // animation-name: zoomIn;
    // animation-duration: 0.3s;
    // animation-timing-function: ease-in-out;
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
    .arrow {
        &::before {
            border-color: rgba(0, 0, 0, 0);
        }
    }
}
;@import "sass-embedded-legacy-load-done:204";