@font-face {
  font-family: 'fifteen-graphics';
  src:  url('fifteen-graphics.eot?iv7mzd');
  src:  url('fifteen-graphics.eot?iv7mzd#iefix') format('embedded-opentype'),
    url('fifteen-graphics.ttf?iv7mzd') format('truetype'),
    url('fifteen-graphics.woff?iv7mzd') format('woff'),
    url('fifteen-graphics.svg?iv7mzd#fifteen-graphics') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fifteen-graphics' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-subscription:before {
  content: "\e90e";
  color: #323ec5;
}
.icon-setting:before {
  content: "\e900";
  color: #323ec5;
}
.icon-notification:before {
  content: "\e901";
  color: #323ec5;
}
.icon-add:before {
  content: "\e902";
  color: #323ec5;
}
.icon-package:before {
  content: "\e903";
  color: #323ec5;
}
.icon-home:before {
  content: "\e904";
  color: #323ec5;
}
.icon-users:before {
  content: "\e905";
  color: #323ec5;
}
.icon-payment:before {
  content: "\e906";
  color: #323ec5;
}
.icon-manager:before {
  content: "\e907";
  color: #323ec5;
}
.icon-invoice:before {
  content: "\e908";
  color: #323ec5;
}
.icon-affiliate:before {
  content: "\e909";
  color: #323ec5;
}
.icon-payment-affiliate:before {
  content: "\e90a";
  color: #323ec5;
}
.icon-gallery:before {
  content: "\e90b";
  color: #323ec5;
}
.icon-sales:before {
  content: "\e90c";
  color: #323ec5;
}
.icon-products:before {
  content: "\e90d";
  color: #323ec5;
}
