.app-footer {
  margin-top: 2rem;
  background: $gray-100;
  padding: 1.25rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .footer-bottom {
    .btn {
      position: fixed;
      bottom: 30px;
      right: 60px;
    }
    width: 100%;
    .logo {
      width: 3rem;
      height: auto;
      margin: 4px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:179";