table {
  &.dataTable-collapse {
    border-collapse: collapse !important;
  }
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
}

;@import "sass-embedded-legacy-load-done:194";