.page-form {
    font-family: Verdana, sans-serif;
}

.pointer {
    cursor: pointer;
}

.spinner.sm {
    height: 2em;
    width: 2em;
}

.back-to-home {
    width: 90%;
    box-sizing: border-box;
    margin-top: 5%;
    margin-bottom: 8%;
    letter-spacing: 1.5px;
    color: #546b8b;
    font-weight: 500;
}

.logo {
    width: 13%;
    align-self: center;
}

.p-viewer {
	z-index: 9999;
	position: absolute;
    top: 10px;
	left: 20px;
    color: #323ec5;
}

.copyright {
    color: #6781a5;
    align-self: center;
    position: absolute;
    bottom: 0;
    margin: 4%;
}

.form-label {
    color: #546b8b;
}
