.font-stats {
    font-size: large !important;
    font-weight: 600 !important;
}

.card-icon-bg [class^='i-'] {
    color: inherit !important;
}

.color-indigo {
    color: var(--indigo);
}

.color-yellow {
    color: var(--yellow);
}

.color-teal {
    color: var(--teal);
}

.color-red {
    color: var(--red);
}

.row {
    width: 100%;
}

.card-body {
    display: block !important;
}
