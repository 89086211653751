.layout-sidebar-vertical{
.vertical-header {
    position: relative!important;
}
.menu-toggle.vertical-toggle {
    display: none!important;
}
.header-part-right{
.dropdown-toggle.btn {
    padding: 0px !important;
}
}
header.main-header.vertical-header.d-flex.justify-content-between {
    border-radius: 5px;
    height: 64px;
}


}
;@import "sass-embedded-legacy-load-done:181";