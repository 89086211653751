.forgot-password {
    margin: 0 auto;
    justify-content: center;
    display: flex;
    float: none;
    flex-direction: column;
    flex-wrap: nowrap;
}

.forgot-password-form {
    width: 50%;
}

.forgot-password-title {
    color: #323ec5;
    font-weight: bold;
    text-align: center;
}

.description {
    color: #546b8b;
    text-align: center;
}

#forgot-password-button {
    background-color: #eb8a61;
    color: white;
    font-size: medium;
    transition-duration: 0.5s;
}

#forgot-password-button:hover {
	background: #ed682d;
    color: white;
}
#forgot-password-button:active,
#forgot-password-button:focus {
    background: #ed682d;
}

.register-link {
    color: #ed682d;
    margin-bottom: 5%;
    font-weight: 600;
}

.center-text {
    color: white;
    letter-spacing: 1px;
    font-weight:900;
}

.center-text-bottom {
    color: white;
}

@media only screen and (max-width : 767px) {
    .forgot-password-form {
      width: 100%;
    }

    .back-to-home {
      width: 100%;
      margin-bottom: 10%;
    }

    .logo {
      width: 25%;
    }
}

