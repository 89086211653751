.ul-product-detail__features ul{
    
}
.ul-product-detail__features ul li{
   list-style: none;
   margin: 8px 0;
}
.ul-counter-cart .btn-group {
   display: flex;
   align-items: center;
}
.ul-counter-cart .btn-group .btn{
 padding: 0px!important;
 border: 0;
 background-color: transparent;
 margin: 0px 14px;
} 
ul.gull-pagination li {
   margin: 0 12px;
 }
 
 ul.gull-pagination li .page-link {
   border-radius: 50% !important;
   border-color: #fff !important;
 }
 .page-link:hover {
   background-color: #fff;
 }
 .page-link:focus {
   box-shadow: 0 0 0 0.2rem rgb(255, 255, 255);
 }
 .page-item.disabled .page-link {
   color: #d5d0d9;
 }
;@import "sass-embedded-legacy-load-done:261";