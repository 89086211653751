#calendar {
    float: right;
    width: 100%;
}

#external-events h4 {
    font-size: 16px;
    margin-top: 0;
    padding-top: 1em;
}

#external-events .fc-event {
    margin: 2px 0;
    cursor: move;
}

.create_event_wrap p {
    margin: 1.5em 0;
    font-size: 11px;
    color: #666;
}

.create_event_wrap p input {
    margin: 0;
    vertical-align: middle;
}

.fc-event {
    position: relative;
    display: block;
    font-size: .85em;
    line-height: 1.3;
    border-radius: 3px;
    border: 0px solid $primary !important;
}

a.fc-day-grid-event {
    background: $primary;
    padding: 5px;
}

th.fc-day-header {
    border-bottom-width: 2px;
    padding: 10px 0px;
    display: table-cell;
    background: #F5F5F5;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

td.fc-head-container {
    padding: 0px !important;
}

.fc-toolbar h2 {
    margin: 0;
    font-weight: bold;
}

span.fa {
    font-family: 'iconsmind' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

span.fa.fa-chevron-left:before {
    content: "\f077";
}

span.fa.fa-chevron-right:before {
    content: "\f07d";
}
;@import "sass-embedded-legacy-load-done:189";