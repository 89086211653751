#task-manager-list {
    .ul-task-manager__dropdown-menu.show {
        display: block;
        margin: 10px 2px;
    }
    .ul-task-manager__header-inline {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
    }
    
    .ul-task-manager__list-icon {
        color: $gray-900;
    }
    .ul-task-manager__list-icon i {
        font-weight: 900;
        padding: 0 4px;
    }
    .ul-task-manager__select-show {
        display: inline;
    }
    .ul-task-manager__search-inline {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .ul-task-manager__head--width{
        width: 60px;
    }
    // check-icon
    .checkmarks {
        display: inline-block;
        width: 22px;
        height: 22px;
        -ms-transform: rotate(45deg);
        /* IE 9 */
        -webkit-transform: rotate(45deg);
        /* Chrome, Safari, Opera */
        transform: rotate(45deg);
    }
    .checkmark_stem {
        position: absolute;
        width: 3px;
        height: 9px;
        background-color: #ccc;
        left: 11px;
        top: 6px;
    }
    .checkmark_kick {
        position: absolute;
        width: 3px;
        height: 3px;
        background-color: #ccc;
        left: 8px;
        top: 12px;
    }
    // end of check icon
    .task-manager-list-select {
        width: 70%;
    }
    .calendar i {
        color:$gray-900;
        font-size: 16px;
        font-weight: 900;
        margin-right: 6px;
    }
    .ul-task-manager__avatar {
        width: 36px;
        height: 36px;
    }
    .ul-task-manager__font-table{
        font-size: 33px;
        position: relative;
        top: 13px;
        color: $gray-700;
        cursor: pointer;
    }
    .ul-task-manager__font-down{
        color: $gray-900;
        font-size: 20px;
    }
    //ul-task-manager__dot color
    .ul-task-manager__dot {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        display: inline-block;
    }
    
    //   end of ul-task-manager__dot color
    .active {
        display: none;
    }
    ul.pagination.justify-content-end {
        margin-bottom: 0;
    }
    // .menu-toggle{}
    // .menu-toggle ul{
    // }
    // ul.menu-list{
    //     margin: 0;
    //     padding: 0;
    //     border:1px solid #ddd9d9;
    // }
    // ul.menu-list li{
    //     list-style: none;
    //     padding: 2px;
    // }
    // ul.menu-list li.custom-line{
    //     width: 100%;
    //     height: 1px;
    //     background-color: #ddd9d9;
    //     padding: 0px!important;
    // }
    td.ul-task-manager__align{
        vertical-align: middle;
    }
    @media (max-width: 991px) {
        .custom-sm-width {
            width: 1000px;
        }
    }
}
;@import "sass-embedded-legacy-load-done:239";