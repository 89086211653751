<template>
  <div>
    <breadcrumb :page="__('Manual Payment Settings')" />

    <b-row>
      <b-col sm="12">
        <b-form @submit.prevent="handleSaveManualPayment" class="pb-3">
          <b-row>
            <b-col sm="12">
              <b-form-group :label="__('Activate')">
                <b-form-checkbox v-model="settings.enabled"></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-show="settings.enabled">
            <b-col sm="12">
              <b-form-group :label="__('Make payment proof required for')">
                <b-form-checkbox-group
                  v-model="settings.settings.paymentProof"
                  :options="[
                    { value: 'ob', text: __('Internet Banking') },
                    { value: 'cdm', text: __('Cash Deposit Machine') },
                  ]"
                  value-field="value"
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="pt-3">
            <b-col sm="12" v-show="settings.enabled">
              <h5>{{ __('Bank Account List') }}</h5>

              <div
                v-if="fetching"
                class="d-flex justify-content-center spinner sm spinner-primary mt-3 text-center"
              ></div>

              <b-table v-else responsive :fields="bankAccountItemsFields" :items="bankAccountItems">
                <template v-slot:cell(index)="line">
                  {{ line.index + 1 }}
                </template>

                <template v-slot:cell(bank_name)="data">
                  <v-select
                    class="custom-v-select"
                    :options="banks"
                    v-model="data.item.bank_name"
                    :placeholder="__('Select bank name...')"
                    v-if="data.item.editable"
                  >
                  </v-select>

                  <span v-else>{{ data.item.bank_name }}</span>

                  <b-form-input
                    :placeholder="__('Enter bank name...')"
                    v-model="data.item.bank_extra"
                    v-show="data.item.bank_name === __('Other bank...')"
                  >
                  </b-form-input>
                </template>

                <template v-slot:cell(name)="data">
                  <b-form-input
                    :placeholder="__('Enter account name...')"
                    v-model="data.item.name"
                    v-if="data.item.editable"
                  >
                  </b-form-input>

                  <span v-else>{{ data.item.name }}</span>
                </template>

                <template v-slot:cell(number)="data">
                  <b-form-input
                    :placeholder="__('Enter account number...')"
                    v-model="data.item.number"
                    v-if="data.item.editable"
                  >
                  </b-form-input>

                  <span v-else>{{ data.item.number }}</span>
                </template>

                <template v-slot:cell(action)="data">
                  <b-button variant="success" @click="makeItemNotEditable($event, data)" v-if="data.item.editable">
                    {{ __('Save Account') }}
                  </b-button>

                  <b-button v-else variant="info" @click="makeItemEditable($event, data)">
                    {{ __('Update Account') }}
                  </b-button>

                  <b-button v-if="data.item.editable" variant="danger" @click="removeInvoiceItem($event, data)">
                    {{ __('Remove Account') }}
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="12">
              <b-button v-show="settings.enabled" variant="outline-primary" @click="addBankAccountItem($event)">
                {{ __('Add Account') }}
              </b-button>

              <b-button type="submit" variant="primary">
                {{ __('Save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>

        <b-alert :variant="success ? 'success' : 'danger'" v-if="message" show>
          {{ message }}
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fetching: false,
      success: false,
      message: '',
      settings: {
        enabled: true,
        settings: {
          paymentProof: [],
        },
      },
      banks: [
        'Affin Bank',
        'Agrobank',
        'Al Rajhi Bank',
        'Alliance Bank',
        'AmBank',
        'Bank Islam',
        'Bank Muamalat',
        'Bank Rakyat',
        'Bank Simpanan Nasional',
        'CIMB Bank',
        'Citibank',
        'HSBC Amanah',
        'HSBC Bank',
        'Hong Leong Bank',
        'Maybank',
        'OCBC Bank',
        'Public Bank',
        'RHB Bank',
        'Standard Chartered',
        'United Overseas Bank (UOB)',
        __('Other bank...'),
      ],
      bankAccountItemsFields: [
        {
          label: __('No.'),
          key: 'index',
        },
        {
          label: __('Bank Name'),
          key: 'bank_name',
          thStyle: { width: '30%' },
        },
        {
          label: __('Account Name'),
          key: 'name',
        },
        {
          label: __('Account Number'),
          key: 'number',
        },
        {
          label: __('Action'),
          key: 'action',
          width: '210px',
        },
      ],
      bankAccountItems: [],
    };
  },

  async mounted() {
    await this.fetchManualPaymentSettings();
  },

  methods: {
    async fetchManualPaymentSettings() {
      this.fetching = true;

      axios
        .get('/api/payment_gateways/showByName/manual payment')
        .then(({ data: { data: settings } }) => {
          this.$set(this, 'settings', { ...settings, enabled: settings.enabled == true });

          this.bankAccountItems = settings.settings.items.map((item) => {
            return {
              id: item.id,
              bank_name: item.bank_name,
              bank_extra: '',
              name: item.name,
              number: item.number,
              editable: false,
            };
          });
        })
        .catch((error) => {
          this.showErrorNotification(this, [error], __('Failed'));
        })
        .finally(() => {
          this.fetching = false;
        });
    },

    async handleSaveManualPayment() {
      this.success = false;
      this.message = '';
      this.errors = {};
      this.processing = true;

      let postData = {
        _method: 'put',
        ...this.settings,
        payment_proof: this.settings.settings.paymentProof,
        items: this.bankAccountItems.map((item) => {
          return {
            id: item.id,
            bank_name: item.bank_name == __('Other bank...') ? item.bank_extra : item.bank_name,
            name: item.name,
            number: item.number,
          };
        }),
      };

      axios
        .post(`/api/payment_gateways/${this.settings.id}`, postData)
        .then(({ data: { message, success } }) => {
          this.message = message;
          this.success = success;
        })
        .catch((error) => {
          this.showErrorNotification(this, [error], __('Failed'));
        })
        .finally(() => {
          this.processing = false;
        });
    },

    addBankAccountItem(e) {
      this.bankAccountItems = [
        ...this.bankAccountItems,
        {
          id: 0,
          bank_name: '',
          bank_extra: '',
          name: '',
          number: '',
          editable: true,
        },
      ];
    },

    makeItemNotEditable(e, data) {
      this.bankAccountItems[data.index].editable = false;
      this.bankAccountItems[data.index].bank_name == __('Other bank...')
        ? (this.bankAccountItems[data.index].bank_name = this.bankAccountItems[data.index].bank_extra)
        : this.bankAccountItems[data.index].bank_name;
    },

    makeItemEditable(e, data) {
      this.bankAccountItems[data.index].editable = true;
      if (!this.banks.includes(this.bankAccountItems[data.index].bank_name)) {
        this.bankAccountItems[data.index].bank_extra = this.bankAccountItems[data.index].bank_name;
        this.bankAccountItems[data.index].bank_name = __('Other bank...');
      }
    },

    removeInvoiceItem(e, data) {
      this.bankAccountItems.splice(data.index, 1);
    },
  },
};
</script>

<style>
.custom-v-select .vs__dropdown-menu {
  position: static;
}
</style>
