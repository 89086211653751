.register-card {
    margin: 0 auto;
    justify-content: center;
    display: flex;
    float: none;
    flex-direction: column;
    flex-wrap: nowrap;
}

#left-text {
    align-items: flex-end;
}

.input-error, .form-control-error:focus {
    border: 3px solid rgb(255 0 0 / 38%);
    box-shadow: none;
}

.card-register-form {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    width: 90%;
}

.password-toggle-register {
    z-index: 9999;
    position: absolute;
    top: 10px;
    right: 4px;
    color: #323ec5;
  }

.pointer {
    color: #ed682d;
}

.p-viewer {
    top: 10px;
    left: 17px;
}

.back-to-home-register-container {
    text-align: left;
    margin-top: 10px;
    margin-left: 30px;
}

.back-to-home-register {
    display: inline-block;
    letter-spacing: 1.5px;
    color: #eff3fc;
    font-weight: 500;
    padding-top: 2%;
    font-size: medium;
}

#submit-button {
    background-color: #ed682d;
    border: #ed682d;
    height: 40px;
    font-size: 12pt;
}

#submit-button:hover {
    background-color: #ed682d;
    box-shadow: 0 12px 16px 0 rgba(229, 123, 1, 0.634);
}

.bg-image {
    background-size: cover;
    background-repeat: no-repeat;
}

.logo-register {
    width: 12%;
    align-self: center;
}

.sign-in-title {
    color: #323ec5;
    font-weight: bold;
    text-align: center;
}

.register-form {
    width: 80%;
    margin: auto;
}

.login-form {
    width:75%;
}

@media only screen and (max-width : 767px) {
    body {
        overflow: auto !important;
    }

    .bg-image {
        background-position: center;
    }

    .card-register-form {
        width: auto !important;
        margin: 3% 1% !important;
    }

    .logo-register {
        width: 25%;
        padding-bottom: 1%;
    }

    .register-form {
        width: 100%;
    }

    .back-to-home-register-container {
        margin-left: 10px;
    }
  }
