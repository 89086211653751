#top-intro {
    background-image: url('@/landing_assets/images/landing-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url('@/landing_assets/images/landing-spinner.gif') center no-repeat #fff;
}

.landing-page-logo{
    width: 51%;
}

.fadein {
    opacity: 0;
    transition: all 1.5s;
    transform: translate(0, 100px);
}

.fadein.visible {
    opacity: 1;
    transform: translate(0, 0);
}

.top-nav-bullet {
    background-color: white;
    float: right;
    padding: 5px;
    border-radius: 40px ;
}

img.intro-image {
    width: 100%;
    margin-left: -8%;
}

.top-section-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    font-size: 28px;
    color: #323ec5;
    border-radius: 50%;
    padding: 10px;
    width: 60px;
    height: 60px;
}

.badge-one, .badge- {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.slogan{
    margin-top: -10%;
}

.navbar-brand-logo {
    width: 25%;
}

h1.top-section-title {
    color: white;
}

.nav-signup-button {
    background: rgb(244,187,5);
    background: linear-gradient(90deg, rgba(244,187,5,1) 2%, rgba(246,202,9,1) 48%, rgba(249,228,35,1) 100%);
    text-decoration: none;
}

.get-started-button {
    background-color: #ed682d;
    background: linear-gradient(#ec682e, #ec682e) padding-box, linear-gradient(to right, #ec956e, #c75a35) border-box;
    border: 3px solid transparent;
    text-decoration: none;
}

#easy-ways, #target-audience, #brands, #feedback {
    background-color: #eff3fc;
}

#target-audience .section-title {
    margin-bottom: 4%;
    margin-top: -2%;
}

.easy-ways-title {
    margin-bottom: 7%;
    width: 45%;
}

.dotted-line {
    position: absolute;
    width: 73%;
    margin-top: 15%;
    margin-left: 1%;
}

#circle1 {
    width: 430px;
    height: 430px;
    box-shadow: 8px 4px 1px 7px #b6bbeb;
    border-radius: 50%;
    background: #fff;
    display: flex;
    margin-right: 15px;
    float: right;
    position: relative;
    align-items: center;
}

.step1, .step3 {
    left: -180px;
}

.step1{
    margin-top: 18%;
}

.step2 {
    left: 7%;
}

.number {
    width: 85px;
    height: 85px;
    border: 10px solid #eff3fc;
    border-radius: 50%;
    background: #323ec5;
    position: absolute;
    font-size: 45px;
    z-index: 1;
    transform: translate(-50%, -50%);
}

.number-1 {
    top: 20%;
    right: 57%;
}

.number-2 {
    right: 22%;
}

.number-3 {
    right: 55%;
}

.panel-icon {
    width: 80%;
}

.step2-panel-icon {
    margin-top: -10%;
}

.circle-title {
    color:#323cc4;
    font-size: xx-large;
    font-weight: bold;
    line-height: 1.2;
}

.circle-content {
    display: flex;
    align-items: baseline;
    font-size: larger;
    padding: 15px;
}

.double-check {
    width: 8%;
    margin-right: 10px;
}

.white-double-tick {
    width: 6%;
    margin-right: 5px;
}

#smart-features .container {
    width:70%;
}

#smart-features ul li{
    font-size: 20px;
}

.orange-card {
    background-color: #ffefeb;
    border-radius: 50px;
    height: 90%;
    margin:auto;
    transition: all .3s ease-in-out;
    border: 3px solid #fcebe7;
}

.payment-gateway-integrations {
    margin-top: -25px;
}

.secured-database {
    margin-top: 10%;
    height: 90%;
}

.invoice-management {
    height: 105%;
}

.fa-ul .fa-li .fa-check-double {
    color:#323cc4;
}

.blue-card:hover, .orange-card:hover {
    background-color: #333dc5;
    color: white;
    border: 3px solid #333dc5;
    transform: translateY(-5px);
    box-shadow: rgba(48, 48, 48, 0.318) 0px 10px 36px 0px, rgba(0, 0, 0, 0.272) 0px 0px 0px 1px;
}

.blue-card:hover .card-title, .orange-card:hover .card-title, .blue-card:hover .fa-ul .fa-li .fa-check-double, .orange-card:hover .fa-ul .fa-li .fa-check-double {
    color: white;
}

.orange-card p, .blue-card p, .darkblue-card p {
    color:#323ec5;
    font-size: 28px;
    padding: 20px;
    font-weight: bold;
}

.blue-card {
    background-color: #eff3fc;
    border-radius: 50px;
    border: 3px solid #d8e4fa;
    transition: all .3s ease-in-out;
    height: 105%;
}

#target-audience .container .row .col-md-6 {
    margin-bottom: 40px;
}

.audience-container {
    height: 130px;
    margin: 10px auto;
    display: contents;
}

.even {
    border-left: 4px solid #3c46b7;
}

.inner-audience-card {
    height: 80px;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -16%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    background-color: #333dc5;
}

.outer-audience-card {
    border: 4px solid #333dc5;
    width: 410px;
    margin: 20px auto;
}

.vl {
    border-left: 3px solid #333dc5;
    height: 100px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
  }

.arrow-right {
    border-top: 43px solid transparent;
    border-bottom: 43px solid transparent;
    border-left: 43px solid #333dc5;
    position:absolute;
    right: 90px;
}

.arrow-left {
    border-top: 43px solid transparent;
    border-bottom: 43px solid transparent;
    border-left: 43px solid #333dc5;
    position:absolute;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    left: -24px;
}

#pills-tabContent .package-card {
    background-color: #eff3fc;
    color:#323ec5;
    border: 2px solid #dce0f5;
    padding: 30px;
    width: auto;
    margin-bottom: 10px;
    border-radius: 40px;
	position:relative;
	height: auto;
	transition: all 0.3s ease;
    cursor: pointer;
	box-shadow: 0 10px 10px rgba(0,0,0,0.12);
}

.package-item{
	width: 120%;
    padding: 0;
    margin-bottom: 20px;
    margin-left: -3%;
}

.package-item td {
	padding: 5px;
}

a.package-button {
    background: linear-gradient(#333dc5, #333dc5) padding-box, linear-gradient(to right, #abb2e7, #3c46b7) border-box;
    border-radius: 50em;
    border: 3px solid transparent;
    text-decoration: none;
    padding: 10px;
    margin-left: 25px;
}

#pills-tabContent .package-card:hover {
    background-color: #323ec5;
    color:white;
    border: 2px solid #323cc4;
    transform: scale(1.03);
	animation-duration: 0.25s;
}

.package-card:hover .price, .package-card:hover .price h6{
	color: white;
}

.package-card:hover .text-three{
	transition: all 0.4s ease;
	color: white;
}

.package-card:hover .package-button{
    background: linear-gradient(#ec682e, #ec682e) padding-box, linear-gradient(to right, #ec956e, #c75a35) border-box;
    border-radius: 50em;
    border: 3px solid transparent;
    text-decoration: none;
}

.package-tab{
	color: #323ec5;
	border: 1px solid #d8e4fa !important;
	border-radius: 20px !important;
	width: 93%;
}

.package-tab.active {
	background-color:#323ec5 !important;
	color: #fff;
	border-color: #323ec5 !important;
	box-shadow:0 0 3px rgba(0,0,0,.15);
	border-radius: 25px;
}

/* package ribbon */
.ribbon {
    width: 155px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #c75a35;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-color: #ec682e;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }

  /* top left ribbon */
  .ribbon-top-left {
    top: -12px;
    left: -12px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 5px;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -20px;
    top: 42px;
    transform: rotate(-45deg);
  }

.brand-circle {
    width: 210px;
    height: 210px;
    box-shadow: 1px 5px 0px 5px #323cc4;
    border-radius: 50%;
    background: #fff;
    display: flex;
    margin: auto;
    padding: 0;
    position: relative;
    align-items: center;
}

#faqs {
    background-image: url('@/landing_assets/images/faq-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.accordion-item {
    background-color: white;
    margin: 10px;
    border-radius: 15px;
}

.accordion-button:not(.collapsed) {
    background-color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.footer-logo {
    width: 28%;
    display: block;
    margin: auto;
}

.footer-text {
    color:#323cc4;
    font-size: large;
}

footer .platform li, footer .support li{
    margin-top: 23px;
    margin-bottom: 23px;
}

footer .platform li a, footer .support li a{
    text-decoration: none;
    margin-left: 10px;
    color: black;
    margin-left: 50px;
    font-size: large;
}

.app-buttons-row {
    display: flex;
    justify-content: end;
}

.app-button-image {
    width: 16%;
}

.app-button {
    width: 10%;
    display: flex;
    justify-content: end;
    text-align:right;
}

.list-title {
    margin-left: 50px;
}

hr.line {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    color: #323cc4;
}

.socials {
    text-align: right;
}

.socials a img{
    text-align: right;
    width: 7%;
}

.language {
    width: 40px;
    margin-bottom: 3px;
}

.vertical-line {
    font-size: 21pt;
    color:#323cc4;
}

/* carousel */
.carousel {
	width: 650px;
	margin: 0 auto;
	padding-bottom: 50px;
}

.carousel .carousel-item {
    background-color: #323cc4;
    border-radius: 30px;
	font-size: 20px;
	overflow: hidden;
	min-height: 350px;
    padding: 30px;
}

.carousel-inner {
    color: white;
}

.carousel img {
	width: 120px;
	border: 6px solid white;
}

.carousel .testimonial {
	padding: 30px 0 10px;
}
.carousel .overview {
	text-align: center;
	padding-bottom: 5px;
}
.carousel .overview b {
	color: #333;
	font-size: 15px;
	text-transform: uppercase;
	display: block;
	padding-bottom: 5px;
}

button[data-bs-target="#carouselExampleCaptions"][data-bs-slide-to] {
    background-color: #323cc4;
}

.carousel .carousel-indicators button {
    width: 10px;
    height: 10px;
    border-radius: 40%;
}

.carousel-control-prev.control-position {
    left: -90px;
    margin:auto;
    background-color: #323cc4;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.carousel-control-next.control-position {
    right: -90px;
    margin:auto;
    background-color: #323cc4;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
/* End carousel */

.testimony.fa-quote-left {
    font-size: 40px;
}

/* Laptop 1024px */
@media screen and (max-width: 1024px) {
    .top-nav-bullet{
        width: 78%;
    }

    .landing-page-logo {
        width: 50%;
    }

    .dotted-line {
        width: 100%;
        margin-top: 21%;
        margin-left: -3%;
    }

    #circle1 {
        width: 410px;
        height: 410px;
    }

    .step1, .step3 {
        left: -10%;
    }

    .step2 {
        left: 20%;
    }

    .number-1 {
        top: 15%;
        left: 42%;
    }

    .number-2 {
        left: 68%;
    }

    .number-3 {
        left: 42%;
        top: -3%;
    }

    .step2-panel-icon {
        margin-top: 0;
        margin-left: 10%;
    }

    .col-md-3.card-section {
        width: auto;
        margin-bottom: 2%;
    }

    .brand-circle {
        width: 150px;
        height: 150px;
    }
}

@media (min-width: 1024px) and (max-width: 1024px) {
    .col-md-3.card-section {
        width: 34%;
    }
}
 /* 4K screen */
@media (min-width:2560px) {
    .dotted-line {
        margin-top: 8%;
        width: 44%;
    }
}

/* Small device */
@media only screen and (max-width:320px) {
    #pills-tabContent .package-card {
        width: 260px;
    }

    .inner-audience-card {
        width: 247px !important;
        padding: 10px 10px !important;
    }

}

/* Mobile devices */
@media only screen and (max-width: 768px)  {
    img.intro-image {
        margin-left: 0%;
    }

    .landing-top-nav .container {
        margin: 0px 10px;
    }

    .slogan {
        margin-top: 10%;
    }

    .logo{
        width: 30%;
    }

    .navbar-brand-logo {
        width: 100%;
    }

    .top-nav-bullet.navbar-collapse {
        background: white;
        border-radius: 10px;
    }

    .app-button-image {
        width: 65%;
    }

    .top-section-badge {
        font-size: 23px;
        margin-bottom: 10px;
        width: 45px;
        height: 45px;
    }

    .easy-ways-title {
        width: 100%;
    }

    .number {
        width: 70px;
        height: 70px;
        font-size: 35px;
    }

    .dotted-line {
        display: none;
    }

    #circle1 {
        width: 315px;
        height: 315px;
        margin: auto;
        float: none;
    }

    .circle-title {
        font-size: x-large;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .circle-content{
        font-size: medium;
        padding: 0%;
    }

    .panel-icon {
        margin-top: -20%;
    }

    .step2-panel-icon {
        margin-top: 5%;
    }

    .step1, .step2, .step3 {
        left: 2%;
        margin-top: 3%;
    }

    .step3 {
        top: 8%;
    }

    .number-1 {
        top: 0;
        right: 25%;
    }

    .number-3 {
        top: 4%;
        right: 18%;
    }

    .even {
        border-left: none;
    }

    .navbar-nav .nav-link {
        color: black;
    }

    .invoice-management img {
        width: 50%;
    }

    #smart-features .container {
        width: 100%;
    }

    .orange-card p, .blue-card p, .darkblue-card p {
        font-size: 25px;
        padding: 15px;
    }

    .orange-card, .blue-card, .darkblue-card, .secured-database{
        margin: 10px;
        height: auto;
        width: 100%;
    }

    .inner-audience-card {
        width: 245px;
        padding: 0px 25px;
        font-size: 18px;
    }

    .outer-audience-card {
        width: auto;
        display: table;
    }

    .arrow-left {
        left: -20px;
    }

    .arrow-right {
        right: -20px;
    }

    .arrow-left-card{
        margin-left: 7%;
    }

    .list-title {
        margin-left: 0;
    }

    .app-buttons {
        display: flex;
        justify-content: center;
    }

    footer .platform li a, footer .support li a, .footer-text{
        margin-left: 12px;
    }

    .app-button-image {
        width: 30%
    }

    .app-button {
        text-align: center;
    }

    .socials a img{
        width: 13%;
        margin: auto;
    }

    .socials {
      text-align: center;
    }

    .carousel {
        width: auto;
    }

    .carousel-control-prev.control-position {
        left: 0px;
    }

    .carousel-control-next.control-position {
        right: 0px;
    }
}

